<template>
  <div class="ad-about">
    <moe-header :bg="background" :mobileBg="background" height="470px" mobileHeight="320px">
      <div class="banner-text-container">
        <div class="en-title">
          <img :src="textImage" />
        </div>
        <h1 class="cn-title">关于我们</h1>
      </div>
    </moe-header>

    <moe-navigation-bar />

    <div class="content-container">
      <div class="title">
        <div class="tit">
          <h2>{{ company }}</h2>
          <p>{{ slogan }}</p>
        </div>
        <div class="pic">
          <img :src="logo" style="height: 64px">
        </div>
      </div>
      <div class="content">
        <img :src="facadeImage" />
        <p class="introduce">{{ introduce }}</p>
      </div>
    </div>

    <moe-footer />
  </div>
</template>

<script>
import { IMAGES } from "@/settings"
import { actions, getters } from "@/store/helper"
import { mapGetters } from "vuex"
import store from "@/store"

export default {
  name: 'AboutUs',
  data() {
    return {
      company: null,
      slogan: null,
      logo: null,
      textImage: null,
      background: null,
      facadeImage: null,
      introduce: null,
    }
  },
  computed: {
    ...mapGetters({
      enterpriseMap: getters.site.enterprise,
      developerMap: getters.site.developer,
      aboutMap: getters.site.about,
    }),
  },
  watch: {
    enterpriseMap(newVal) {
      this.introduce = newVal.introduce
    },
    developerMap(newVal) {
      this.company = newVal.companyCN
      this.slogan = newVal.companyEN
    },
    aboutMap(newVal) {
      this.textImage = newVal.aboutTI
      this.background = newVal.aboutBG
      this.facadeImage = newVal.facadeIMG
      this.logo = newVal.logoIMG
    },
  },
  created() {
    store.dispatch(actions.site.fetchEnterprise)
    store.dispatch(actions.site.fetchSite)
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ad-about {
    .banner-text-container {
      display: none !important;
    }
    .content-container {
      width: 100%;
      max-width: 100%;
      margin: -50px auto auto auto !important;
      padding: 20px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      .title {
        width: 100% !important;
        height: auto !important;
        padding-top: 30px !important;
        .tit {
          h2 {
            font-size: 18px !important;
          }
          p {
            font-size: 9px !important;
          }
        }
        .pic {
          display: none !important;
          img {
            height: 54px;
          }
        }
      }
      //img {
      //  width: 100% !important;
      //}
    }
    .content {
      width: 100% !important;
      margin: 30px auto auto auto !important;
      padding-bottom: 30px !important;
      img {
        display: none;
        width: 100% !important;
        height: auto !important;
      }
      p {
        font-size: 13px !important;
        margin-bottom: 30px !important;
        &:last-child {
          margin-bottom: 0px !important;
        }
      }
    }

    .footer-container {
      margin-top: 0px !important;
    }
  }
}
.ad-about {
  width: 100%;
  background: #f6f9fa;
  .banner-text-container {
    max-width: 1180px;
    height: 100%;
    text-align: center;
    padding-top: 175px;
    h1 {
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      margin-top: 18px;
    }
  }

  .content-container {
    max-width: 1180px;
    box-sizing: border-box;
    margin: -170px auto auto auto;
    background: #ffffff;
    border-radius: 20px;
    position: relative;
    z-index: 10;
    .title {
      width: 948px;
      height: 170px;
      box-sizing: border-box;
      margin: 0px auto auto auto;
      display: flex;
      align-items: flex-start;
      padding-top: 100px;

      .tit {
        flex: 1;
        h2 {
          font-size: 30px;
          font-weight: 600;
          color: #222222;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          letter-spacing: 2px;
        }
      }
    }
  }
  .content {
    width: 948px;
    margin: 80px auto;
    padding-bottom: 100px;
    img {
      width: 948px;
      height: 270px;
      margin-bottom: 50px;
      border-radius: 12px;
      object-fit: cover;
    }
    //p {
    //  font-size: 14px;
    //  font-weight: 400;
    //  color: #222222;
    //  line-height: 32px;
    //  margin-bottom: 45px;
    //}
    .introduce {
      font-size: 1.1rem;
      line-height: 2rem;
      font-weight: 400;
      color: #222222;
      //text-indent: 2em;       // 首行缩进
      white-space: pre-line;    // 识别换行
      overflow: hidden;
      margin-bottom: 45px;
    }
  }

  .footer-container {
    margin-top: 100px;
  }
}
</style>
